/* homecom1.css*/

.com1 {
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  margin: 10px 10px;
}

.card_l {
  width: 73%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  background-color: white;
  border-radius: 10px;
  border: none;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.l_img {
  height: 300px;
  overflow: hidden;
}
.l_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease-in-out;
}
.card_l:hover img {
  transform: scale(1.1);
}

.l_text {
  flex: 2;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.l_head {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.l_catagory {
  font-size: 0.9rem;
  color: #999;
  margin-bottom: 15px;
}

.l_content {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.6;
  overflow: hidden;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  display: -webkit-box;
}

.l_srcN {
  font-size: 0.9rem;
  color: #777;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.l_srcN span {
  color: #333;
}

.card_adv {
  width: 25%;
  height: 300px;
  background-color: #e0e0e0;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}
.card_adv img {
  width: 100%;
  height: 100%;
}
/* Responsive Design */
@media (max-width: 768px) {
  .card_l {
    flex-direction: column;
  }
  .l_img {
    max-width: 100%;
  }

  .l_text {
    padding: 10px;
  }

  .l_head {
    font-size: 1.25rem;
  }

  .l_content {
    font-size: 0.9rem;
  }

  .card_adv {
    width: 100%;
    max-height: 400px;
    margin: 20px 0;
    border-radius: 0px;
  }
  .card_adv img {
    width: 60%;
    height: 90%;
    margin: 2% 20%;
  }
}
@media screen and (max-width: 480px) {
  .card_l {
    flex-direction: column;
  }
  .l_img {
    max-width: 100%;
  }

  .l_text {
    padding: 10px;
  }

  .l_head {
    font-size: 1.25rem;
  }

  .l_content {
    font-size: 0.9rem;
  }

  .card_adv {
    width: 100%;
    border-radius: 0px;
    max-height: 300px;
  }
  .card_adv img {
    width: 100%;
    height: 100%;
    margin: 0% 0%;
  }
}
