/* homecom2.css*/

.main-content {
  margin: 0 10px;
  display: grid;
  grid-column-gap: 10px;
  grid-template-areas: "pic1 pic2 pic3 pic4";
}
.itm1 {
  grid-area: pic1;
}
.itm2 {
  grid-area: pic2;
}
.itm3 {
  grid-area: pic3;
}
.itm4 {
  grid-area: pic4;
}

/* Card container */
.card {
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.cardimg {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.cardimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cardBottom {
  padding: 15px;
}

.head {
  font-size: 1em;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
  color: #333;
}

.srcN {
  display: flex;
  justify-content: space-between;
  font-size: 0.85em;
  color: #888;
}

/* Card sizes based on itm class */
.itm1 {
  flex-basis: 48%;
}

.itm2 {
  flex-basis: 48%;
}

.itm3 {
  flex-basis: 48%;
}

.itm4 {
  flex-basis: 48%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .itm1,
  .itm2,
  .itm3,
  .itm4 {
    flex-basis: 100%;
  }
}
