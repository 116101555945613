/* table.css */
.standing {
  width: 100%;
  margin: 20px auto;
  padding: 10px;
}

.standing table {
  width: 70%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  border: 1px solid #ddd;
}
.standing thead th {
  background-color: #343a40;
  color: white;
}
.standing th,
.standing td {
  padding: 12px 8px;
  text-align: center;
}
.standing th {
  font-weight: 600;
}
.standing tbody tr:nth-child(odd) {
  background-color: #f1f1f1;
}

.standing tbody tr:nth-child(even) {
  background-color: #ffffff;
}
/* Styling the club logo and name together */
.team-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}

.team-logo img {
  width: 10%;
  border-radius: 50%;
}

.team-logo span {
  text-align: start;
}

/* Form list styles */
.mobile-only ul {
  padding: 0;
  list-style-type: none;
  display: flex;
}

.mobile-only ul li {
  margin: 0 2px;
  padding: 5px;
  width: 25px;
  height: 25px;
  background-color: green;
  color: rgb(12, 12, 12);
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}

/* Mobile-specific rules */
@media screen and (max-width: 768px) {
  .standing table {
    width: 100%;
  }
  .mobile-only {
    display: none;
  }

  .standing th:nth-child(3),
  .standing td:nth-child(3),
  .standing th:nth-child(5),
  .standing td:nth-child(5),
  .standing th:nth-child(6),
  .standing td:nth-child(6),
  .standing th:nth-child(7),
  .standing td:nth-child(7),
  .standing th:nth-child(8),
  .standing td:nth-child(8) {
    display: none;
  }

  .standing th,
  .standing td {
    padding: 10px 6px;
    font-size: 14px;
  }
  .team-logo img {
    width: 7%;
  }
}

@media screen and (max-width: 480px) {
  .standing th,
  .standing td {
    padding: 10px 6px;
    font-size: 14px;
  }
  .standing td {
    border: none;
  }
  .team-logo img {
    width: 12%;
  }
}
