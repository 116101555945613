body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.all-container {
  margin: auto;
}

.home-container {
  display: flex;
  flex-direction: column;
  background-color: white;
}
.card_adv1 img {
  width: 100%;
  height: 180px;
  padding: 4px 10px;
}
.live-match {
  display: flex;
  flex-direction: column;
}
.live {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 10px auto;
  padding: 10px 14px;
  border: 0.1px solid green;
  border-radius: 6px;
}
.live span {
  font-size: 20px;
  margin: 0 10px;
}
.live-status {
  font-size: 10px;
  margin: 0 10px;
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  animation: flash 2s infinite;
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .live {
    width: 98%;
    padding: 6px 12px;
    border-radius: 4px;
  }
  .live span {
    font-size: 16px;
    margin: 0 6px;
  }

  .card_adv1 img {
    width: 100%;
    height: auto;
    padding: 4px 10px;
  }
}
@media screen and (max-width: 480px) {
  .card_adv1 img {
    width: 100%;
    height: auto;
    padding: 4px 10px;
  }
}
