/* nextMatch.css */
.soccerContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 30px 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
}
.nextMatch {
  padding: 10px;
  width: 60%;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.nextMatch h2 {
  text-align: center;
  padding-bottom: 20px;
}

.match {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
}

.teamHome,
.teamAway {
  display: flex;
  flex-direction: column; /* Stack logo and name vertically */
  align-items: center; /* Center align the logo and name */
  width: 60%;
}

.homeTeam,
.awayTeam {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  text-align: center; /* Center align the text below the logo */
}

.match img {
  width: 100px;
  height: 200px;
  object-fit: contain;
}

.match p {
  font-size: 18px;
  color: #ffffff;
  background-color: #101111;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: bold;
  text-align: center;
}

.venu {
  font-size: 14px;
  color: #666;
  display: flex;
  padding-top: 10px;
  justify-content: center;
  gap: 40px;
}

.venu span {
  font-weight: 500;
}

.nomatch {
  font-size: 18px;
  color: #d9534f;
  margin-top: 20px;
  text-align: center;
}
/* CSS for FootballTable component */
.standingHome {
  width: 30%;
}

.standingHome table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  border: 1px solid #ddd;
}

.standingHome thead {
  background-color: #343a40;
  color: white;
}

.standingHome th,
.standingHome td {
  padding: 12px 8px;
  text-align: center;
}

.standingHome th {
  font-weight: 600;
}

.standingHome tbody tr:nth-child(odd) {
  background-color: #f1f1f1;
}

.standingHome tbody tr:nth-child(even) {
  background-color: #ffffff;
}

.club-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}

.club-logo img {
  width: 10%;
  border-radius: 50%;
}
.club-logo span {
  text-align: start;
}
.toggle-btn {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #0f0f0f;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.toggle-btn:hover {
  background-color: #1f1d1d;
}

/* Mobile devices (less than 768px wide) */
@media screen and (max-width: 480px) {
  .soccerContainer {
    flex-direction: column;
    padding: 20px 0;
  }

  .nextMatch {
    width: 100%; /* Maximize width on mobile */
  }

  .match {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px;
  }

  .teamHome,
  .teamAway {
    width: 100%;
    margin-bottom: 15px; /* Increase space between logos */
  }

  .homeTeam,
  .awayTeam {
    font-size: 12px; /* Reduce font size on mobile */
  }

  .match img {
    width: 50px;
    height: 60px; /* Reduce image size for mobile */
  }
  .match p {
    font-size: 14px;
    color: #ffffff;
    background-color: #101111;
    padding: 8px 16px;
    border-radius: 6px;
    font-weight: bold;
    text-align: center;
  }

  .venu {
    font-size: 12px;
    align-items: center;
    gap: 100px;
  }
  .standingHome {
    width: 100%;
    padding: 10px;
  }
  .standingHome th,
  .standingHome td {
    padding: 10px 6px;
    font-size: 14px;
  }

  .club-logo img {
    width: 12%;
  }
  .toggle-btn {
    font-size: 14px;
    padding: 8px;
  }
}
