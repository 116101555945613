/* weeklyMatch.css */

.result {
  font-family: Arial, sans-serif;
  width: 60%;
  margin: 20px 10px;
  min-height: 450px;
}
.result-header {
  font-size: 18px;
  color: #f4f4f4;
  width: 100%;
  text-align: center;
  padding: 6px 0;
  background-color: #3f3e3e;
  margin-bottom: 6px;
}
.date-carousel-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.date-carousel {
  display: flex;
  gap: 10px;
  flex-grow: 1;
  justify-content: center;
}

.carousel-control {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s;
}

.carousel-control:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

.carousel-button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.carousel-button.active {
  background-color: #646464;
  color: #fff;
}

.carousel-button:hover:not(.active) {
  background-color: #e0e0e0;
}

.result-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.result-info {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.result-info img {
  width: 6%;
}
.result-span {
  width: 20%;
  text-align: center;
}
.result-goal {
  font-size: 18px;
  background-color: #f4f4f4;
  padding: 5px 10px;
  border-radius: 5px;
}

.result-details {
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
  color: #666;
  font-size: 14px;
}

.result-details span {
  margin-right: 10px;
}

.r-no-matches-message {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a3a3a3;
  height: 200px;
  margin: 20px 0;
  border-radius: 10px;
}
.r-no-matches-message p{
  color: #000;
  font-size: 26px;
  font-weight: bold;
}

/* Media query for mobile devices (screen width 480px or less) */
@media (max-width: 480px) {
  .result {
    width: 100%;
    margin: 20px 0;
    padding: 0 10px;
    min-height: 450px;
  }
  .result p {
    font-size: 16px;
    padding: 4px 0;
  }

  .carousel-control {
    padding: 4px 8px;
    font-size: 14px;
  }

  .carousel-button {
    padding: 4px 10px;
    font-size: 14px;
  }

  .result-card {
    padding: 8px;
    margin-bottom: 8px;
    box-shadow: none; /* Reduce shadow for a flatter look */
  }

  .result-info img {
    width: 10%; /* Ensure the images don't dominate the layout */
  }

  .result-span {
    width: 20%;
    font-size: 12px;
  }

  .result-goal {
    font-size: 14px;
    padding: 3px 6px;
  }

  .result-details {
    font-size: 12px;
  }

  .no-matches-message {
    font-size: 16px;
    margin: 15px 0;
  }
}
