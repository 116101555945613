.footer {
  margin-top: 20px;
  bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
}
.footer p {
  text-align: center;
  color: #e7d245;
  font-size: 18px;
  width: 100%;
  line-height: 50px;
  background-color: #228b22;
}
.social-btn {
  padding: 10px 0;
}
.social {
  padding: 0 10px;
}
.fb {
  color: #1877f2;
}
.instagram {
  color: #e1306c;
}
.youtube {
  color: #ff0000;
}
.telegram {
  color: #0088cc;
}
@media screen and (max-width: 768px) {
  .footer {
    margin-top: 20px;
    bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .footer p {
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .footer {
    margin-top: 20px;
    bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .footer p {
    font-size: 14px;
  }
}
