/* weeklyMatch.css */

.weekly-matches {
  font-family: Arial, sans-serif;
}
.weekly-matches p {
  font-size: 18px;
  color: #f4f4f4;
  width: 100%;
  text-align: center;
  padding: 6px 0;
  background-color: #3f3e3e;
  margin-bottom: 6px;
}
.date-carousel-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.date-carousel {
  display: flex;
  gap: 10px;
  flex-grow: 1;
  justify-content: center;
}

.carousel-control {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s;
}

.carousel-control:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

.carousel-button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.carousel-button.active {
  background-color: #646464;
  color: #fff;
}

.carousel-button:hover:not(.active) {
  background-color: #e0e0e0;
}

.match-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.match-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.team-info {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.team-info img {
  width: 10%;
}
.team-span {
  width: 20%;
  text-align: center;
}
.goal {
  font-size: 18px;
  background-color: #f4f4f4;
  padding: 5px 10px;
  border-radius: 5px;
}

.match-details {
  display: flex;
  justify-content: space-around;
  color: #666;
  font-size: 14px;
}

.match-details span {
  margin-right: 10px;
}

.no-matches-message {
  color: #777;
  text-align: center;
  margin: 20px 0;
  font-size: 18px;
}
@media (max-width: 768px) {
  .carousel-button {
    padding: 6px 12px;
    font-size: 16px;
  }

  .match-card {
    padding: 10px;
    margin-bottom: 10px;
  }

  .team-info img {
    width: 15%; /* Increase image size for better visibility */
  }

  .team-span {
    width: 30%; /* Adjust text span to fit better */
  }

  .goal {
    font-size: 16px;
    padding: 4px 8px;
  }

  .match-details {
    font-size: 13px;
  }
}

/* Media query for mobile devices (screen width 480px or less) */
@media (max-width: 480px) {
  .weekly-matches p {
    font-size: 16px;
    padding: 4px 0;
  }

  .carousel-control {
    padding: 4px 8px;
    font-size: 14px;
  }

  .carousel-button {
    padding: 4px 10px;
    font-size: 14px;
  }

  .match-card {
    padding: 8px;
    margin-bottom: 8px;
    box-shadow: none; /* Reduce shadow for a flatter look */
  }

  .team-info img {
    width: 10%; /* Ensure the images don't dominate the layout */
  }

  .team-span {
    width: 30%;
  }

  .goal {
    font-size: 14px;
    padding: 3px 6px;
  }

  .match-details {
    font-size: 12px;
  }

  .no-matches-message {
    font-size: 16px;
    margin: 15px 0;
  }
}
