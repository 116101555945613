/* LoadingSpinner.css */

.spiner-container {
  width: 500px;
  height: 300px;
  margin: 200px auto;
}

.spinner {
  position: relative;
  margin: 100px auto;
  width: 100px;
  height: 100px;
}

.spinner p {
  position: absolute;
  top: 60px;
  font-weight: 500;
  font-size: 20px;
  left: 30px;
  color: #228b22;
}

.circle {
  position: absolute;
  border-radius: 50%;
  border: 8px dotted transparent;
}

.yellow {
  width: 150px;
  height: 150px;
  top: 0;
  left: 0;
  border-top-color: #fad904;
  animation: rotateClockwise 1.5s linear infinite;
}

.green {
  width: 150px;
  height: 150px;
  top: 10px;
  left: 0;
  border-top-color: #06f706;
  animation: rotateCounterClockwise 1.5s linear infinite;
}

@keyframes rotateClockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotateCounterClockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

/* Media Query for Smaller Screens */
@media (max-width: 768px) {
  .spiner-container {
    width: 300px;
    height: 200px;
    margin: 100px auto;
  }

  .spinner {
    width: 80px;
    height: 80px;
    margin: 50px auto;
  }

  .spinner p {
    top: 45px;
    font-size: 16px;
    left: 20px;
  }

  .yellow,
  .green {
    width: 120px;
    height: 120px;
  }
}
