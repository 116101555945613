/* src/components/allCSSfiles/news.css */

.news-container {
  display: flex;
  padding: 0 10px;
  gap: 10px;
  margin-top: 10px;
}
.div-main{
  width: 70%;
}
.adv-side{
  width: 30%;
  background-color: #bebebe;
  padding: 0 10px;
}
.news-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.news-card {
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  width: calc(33.333% - 10px); /* Adjust card size */
  height: 340px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
.news-cardimg{
  width: 100%;
  height: 200px;
  overflow: hidden;
}
.news-cardimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.news-cardBottom {
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
  background-color: white;
}
.news-head {
  font-size: 1em;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
  color: #333;
}

.news-srcN {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  color: #666;
}
@media (max-width: 768px) {
  .news-container {
    margin-top: 10px;
    padding: 0 10px;
    gap: 10px;
  }
  .div-main{
    width: 60%;
  }
  .adv-side{
    width: 40%;
    background-color: #bebebe;
    padding: 0 10px;
  }
  .news-card {
    width: calc(50% - 10px); /* Adjust card size to fit two per row */
  }
}

/* Media query for mobile devices (screen width 480px or less) */
@media (max-width: 480px) {
  .news-container {
    flex-direction: column;
    margin-top: 10px;
    padding: 0 10px;
    gap: 10px;
  }
  .div-main{
    width: 100%;
  }
  .adv-side{
    width: 100%;
    margin-top: 20px;
  }
  .news-list {
    gap: 10px;
  }
  .news-card {
    width: 100%;
  }
  .news-cardBottom {
    padding: 8px;
  }
  .head {
    font-size: 1em;
  }
  .news-srcN {
    font-size: 0.8em;
  }
}
