/* Navbar css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  background-color: #228b22; /* Forest Green */
  color: #e7d245; /* Bright Yellow */
  padding: 10px 20px;
  position: sticky; /* Fixed to the top when scrolling */
  top: 0;
  z-index: 1000; /* Keep it above other content */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Slight shadow for depth */
}

.logo-mini {
  display: none;
}

.nav-link {
  text-decoration: none;
  margin-left: 10px;
  font-weight: bold;
  font-size: 18px;
  padding: 8px 16px;
  color: #e7d245; /* Bright Yellow */
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

.nav-link:hover {
  color: #7fff00; /* Chartreuse */
  background-color: rgba(0, 0, 0, 0.3); /* Dark background on hover */
  border-radius: 6px; /* Rounded corners */
}

.active {
  color: #e7d245; /* Neon Yellow */
  font-weight: bold;
  border: 1px solid #ffffff; /* White border */
  border-radius: 8px;
}

.menu-bar {
  display: none;
}

.bar {
  height: 4px;
  background-color: #e7d245; /* Yellow for the burger menu bars */
  border-radius: 2px;
}

.close-sign {
  font-size: 24px;
  color: #e7d245; /* Yellow close icon */
  cursor: pointer;
}

.buttons {
  display: flex;
  gap: 20px;
}

/* Mobile styles */
@media (max-width: 768px) {
  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #228b22;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1000; /* Ensure it appears above other content */
  }

  .nav-link {
    margin: 0 10px;
    color: #e7d245; /* Yellow for mobile links */
  }

  .buttons.open {
    transform: translateX(0); /* Slide in effect */
  }

  .logo-mini {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #e7d245; /* Yellow for logo on mobile */
    height: 50px;
    padding: 0 10px;
  }

  .close-sign {
    font-size: 24px;
    color: #e7d245; /* Yellow close sign */
    cursor: pointer;
  }

  .menu-bar {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 20px;
    width: 25px;
  }
}
