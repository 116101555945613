/* newsDis.css */
.news {
  display: flex;
}
.newsdis {
  display: flex;
  flex-direction: column;
  margin: 10px 10px;
  width: 70%;
}
.adv-news {
  width: 30%;
  margin: 10px 10px;
}
.newsdis img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-bottom: 20px;
}

.newstext {
  max-width: 100%;
  padding: 0 10px;
}

.newstext h3 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 10px;
}

.newstext hr {
  margin: 10px 0;
}

.newstext span {
  font-size: 1.2rem;
  line-height: 1.5;
}

.newstext p {
  font-weight: bold;
  color: gray;
  margin-top: 15px;
  font-size: 0.9rem;
}

/* Error Styling */
.error {
  color: red;
  text-align: center;
  font-size: 1.2rem;
  margin: 20px 0;
}

/* Related News Section */
.news-related {
  margin-top: 30px;
}

.news-related h2 {
  margin-bottom: 15px;
  font-size: 1.8rem;
  text-align: center;
}

/* Related News Grid */
.new {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns on large screens */
  gap: 20px;
}

.card {
  height: 320px;
  display: flex;
  flex-direction: column;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cardimg img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.card:hover img {
  opacity: 0.85;
  transform: scale(1.05); /* Slight zoom effect */
}

.card:hover {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); /* Shadow on hover */
  transform: translateY(-5px); /* Lift effect */
}

.cardBottom {
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
  background-color: white;
}

.srcN {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  color: gray;
}

.srcN span {
  margin-right: 8px;
  margin-left: 4px;
}

.head {
  font-size: 1em;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
  color: #333;
}

/* Responsive Styling */
@media screen and (max-width: 1024px) {
  .new {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .new {
    grid-template-columns: repeat(2, 1fr);
  }

  .newsdis img {
    height: 300px;
  }
}

@media screen and (max-width: 480px) {
  .news {
    display: block;
  }
  .newsdis {
    width: 100%;
    margin: 0;
  }
  .adv-news {
    width: 100%;
    padding: 0 10px;
    margin: 10px 0;
  }
  .new {
    grid-template-columns: 1fr;
  }

  .newsdis img {
    height: 200px;
  }
}
