.cardadv {
  width: 100%;
  height: 200px;
  border-top: 0.1px solid rgb(240, 240, 240);
  border-bottom: 0.1px solid rgb(240, 240, 240);
  background-color: rgb(240, 240, 240);
}
.cardadv img {
  width: 100%;
  height: 110px;
  padding: 0 30px;
  margin: 46px auto;
}
@media screen and (max-width: 768px) {
  .itm1 {
    margin: auto 0;
  }
  .itm2 {
    margin: auto 0;
  }
  .itm3 {
    margin: auto 0;
  }
  .itm4 {
    margin: auto 0;
  }
  .main-content {
    display: grid;
    grid-row-gap: 10px;
    grid-template-areas:
      "pic1 pic2"
      "pic3 pic4";
  }
  .com1 {
    margin-top: 20px;
    display: block;
  }
  .card_l {
    width: 98%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    overflow-x: hidden;
    margin: auto;
    cursor: pointer;
    height: 300px;
  }
  .cardadv {
    width: 100%;
  }
  .cardadv img {
    width: 100%;
    height: 110px;
    margin: 40px auto;
  }
}

/* Media Query for Mobile */
@media screen and (max-width: 480px) {
  .itm1 {
    margin: auto 0;
  }
  .itm2 {
    margin: auto 0;
  }
  .itm3 {
    margin: auto 0;
  }
  .itm4 {
    margin: auto 0;
  }
  .main-content {
    display: grid;
    grid-template-areas:
      "pic1"
      " pic2"
      "pic3"
      " pic4";
  }
  .com1 {
    margin-top: 2px;
    display: block;
  }
  .card_l {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    margin: auto;
    cursor: pointer;
    height: 360px;
  }
  .cardadv {
    width: 100%;
    height: 140px;
  }
  .cardadv img {
    width: 100%;
    height: 70px;
    padding: 0 10px;
    margin: 30px auto;
  }
}
